//1. 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'node_modules/bootstrap/scss/functions';

// 2. Include default variable overrides here
@import '../variables/variables';
@import '../variables/mixins';
@import 'core';

// 3. Include remainder of required Bootstrap stylesheets
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';
@import 'node_modules/bootstrap/scss/utilities';

//Bootstrap
@import 'bootstarp';

@import 'pages';

@import 'utilities/utilities';

html,
body {
  height: 100%;
  width: 100%;
  font-family: $base-font;
}

main {
  width: 100%;
  flex: 1;
  align-self: stretch;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.text-inherit {
  color: inherit;
}

a {
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.btn-link {
  text-decoration: none;
  color: $white;
  &:focus {
    box-shadow: none;
  }
}
