$sides: (
  '': '',
  't': 'top',
  'b': 'bottom',
  'l': 'left',
  'r': 'right'
);

$breakpointsnew: (
  xs: 320px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1366px,
  xxxl: 1940px
) !default;

$unit: 'px';

@each $breakName, $breakValue in $breakpointsnew {
  @each $sideName, $sideValue in $sides {
    @for $i from 0 through 20 {
      $property: if($sideName == '', '', -#{$sideValue});
      $space: $i * 1;
      $selector: '';

      @if $breakName != '' {
        $selector: #{$sideName}-#{$breakName}-#{$i};
      } @else {
        $selector: #{$sideName}-#{$i};
      }

      @if $breakName != '' {
        @media (min-width: $breakValue) {
          .row {
            &.gutter#{$selector} {
              margin-left: #{-$space + $unit} !important;
              margin-right: #{-$space + $unit} !important;
              [class^='col-'] {
                padding-left: #{$space + $unit} !important;
                padding-right: #{$space + $unit} !important;
              }
            }
          }
        }
      } @else {
        .row {
          &.gutter#{$selector} {
            margin-left: #{- ($space + $unit)} !important;
            margin-right: #{- ($space + $unit)} !important;
            [class^='col-'] {
              padding-left: #{$space + $unit} !important;
              padding-right: #{$space + $unit} !important;
            }
          }
        }
      }
    }
  }
}
