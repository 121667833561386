$breakpointsnew: (
  xs: 320px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1366px,
  xxxl: 1940px
) !default;

$unit: 'px';

@each $breakName, $breakValue in $breakpointsnew {
  @for $i from 0 through 100 {
    $selector: '';
    $space: $i;

    @if $breakName != '' {
      $selector: #{$breakName}-#{$i};
    }

    @if $breakName != '' {
      @media (min-width: $breakValue) {
        .fs-#{$selector} {
          font-size: #{$space + $unit} !important;
        }
      }
    }
  }
}
