// .accordion-button {
//     position: relative;
//     overflow: hidden;
//     cursor: pointer;
// }

.btn-present {
  border-radius: 0px;
  &.active {
    background-color: $primary;
    color: #fff !important;
  }
}
